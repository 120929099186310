var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    { staticClass: "content", staticStyle: { padding: "20px 40px" } },
    [
      _c("h4", [_vm._v("Nový doklad")]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-select", {
              attrs: {
                options: _vm.dT_selectOptions,
                "stack-label": "Druh dokladu"
              },
              model: {
                value: _vm.doc.documentType,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "documentType", $$v)
                },
                expression: "doc.documentType"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-input", {
              attrs: { "stack-label": "Čislo dokumentu", disabled: "disabled" },
              model: {
                value: _vm.doc._id,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "_id", $$v)
                },
                expression: "doc._id"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c(
              "q-search",
              {
                attrs: {
                  name: "name",
                  "stack-label": "Jméno",
                  placeholder: "Zadejte jméno"
                },
                model: {
                  value: _vm.doc.name,
                  callback: function($$v) {
                    _vm.$set(_vm.doc, "name", $$v)
                  },
                  expression: "doc.name"
                }
              },
              [
                _c("q-autocomplete", {
                  attrs: { "min-characters": 2 },
                  on: { search: _vm.search, selected: _vm.selected }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-input", {
              attrs: {
                name: "yob",
                "stack-label": "Rok narození",
                placeholder: "Rok narození"
              },
              model: {
                value: _vm.doc.yob,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "yob", $$v)
                },
                expression: "doc.yob"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: { name: "item", "stack-label": "Položka #1" },
              model: {
                value: _vm.doc.items[0].name,
                callback: function($$v) {
                  _vm.$set(_vm.doc.items[0], "name", $$v)
                },
                expression: "doc.items[0].name"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-input", {
              attrs: {
                name: "price",
                "stack-label": "Cena (Kč)",
                placeholder: "Zadejte cenu"
              },
              model: {
                value: _vm.doc.items[0].price,
                callback: function($$v) {
                  _vm.$set(_vm.doc.items[0], "price", $$v)
                },
                expression: "doc.items[0].price"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-datetime", {
              attrs: {
                type: "datetime",
                "stack-label": "Datum a čas",
                "display-value": _vm.displayDatetime,
                format24h: "format24h",
                "default-selection": _vm.now,
                "clear-label": "aktuální čas"
              },
              on: { change: _vm.onDatetimeSet },
              model: {
                value: _vm.doc.date,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "date", $$v)
                },
                expression: "doc.date"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-select", {
              attrs: {
                options: _vm.pM_selectOptions,
                "stack-label": "Způsob platby"
              },
              model: {
                value: _vm.doc.payment_method,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "payment_method", $$v)
                },
                expression: "doc.payment_method"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "q-btn",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { icon: "create", color: "primary" },
          on: { click: _vm.create }
        },
        [_vm._v("Vytisknout a uložit")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4eb08897", { render: render, staticRenderFns: staticRenderFns })
  }
}