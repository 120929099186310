<template>
<div style="width: 800px; margin: auto" id="receipt">
	<table>
		<tr>
			<td style="width: 75%; padding-left: 20px">
				<strong>Příjmový pokladní doklad</strong>
			</td>
			<td style="width: 25%">
				Číslo dokladu: &nbsp;&nbsp;<strong>{{doc._id.slice(-8)}}</strong>
			</td>
		</tr>
	</table>

	<div class="receipt" style="padding-bottom: 12px">

		<table>
			<tr>
				<td valign="top" style="width: 33%">
					<div style="padding-bottom: 6px">
						<small><i>Firma:</i></small>
					</div>
					<p>
						<i>{{billingInfo.company}}</i>
						<br />
						<i>{{billingInfo.name}}</i>
						<br />
						<i>{{billingInfo.street}}</i>
						<br />
						<i>{{billingInfo.zip}} {{billingInfo.city}}</i>
					</p>
					<p>
						<i>IČ: {{billingInfo.idnum}}</i>
					</p>
					<p>
						<i>Tel.: {{billingInfo.mobile}}</i>
						<br />
						<i>E-mail: {{billingInfo.email}}</i>
						<br />
						<i>{{billingInfo.web}}</i>
					</p>					
				</td>
				<td valign="top" style="width: 33%">
					<div style="padding-bottom: 6px">
						<small><i>Přijato od (jméno, rok narození):</i></small>
					</div>
					<p>{{doc.name}}, {{doc.yob}}</p>
					<div style="padding-bottom: 6px">
						<small><i>Datum a čas vystavení:</i></small>
					</div>
					<p>{{new Date(doc.date).toLocaleString()}}</p>					
				</td>
				<td valign="top" style="width: 33%">
					<table  style="margin-top: -2px">
						<tr>
							<td valign="top" style="width: 160px">
								<div style="padding-bottom: 6px">
									<small><i>Položka:</i></small>
								</div>
							</td>
							<td valign="top">
								<div style="padding-bottom: 6px">
									<small><i>Částka:</i></small>
								</div>
							</td>
						</tr>
						<tr>
							<td valign="top">
								<div style="padding-bottom: 36px; margin-top: -5px">
									{{doc.items[0].name}}
								</div>
							</td>
							<td valign="top" style="text-align: right">
								<div style="padding-bottom: 36px; margin-top: -5px">
									{{doc.items[0].price}},-&nbsp;Kč
								</div>
							</td>
						</tr>
						<tr>
							<td valign="top">
								<div style="padding-bottom: 6px">
									<strong>Celkem:</strong>
								</div>
							</td>
							<td valign="top" style="text-align: right">
								<div style="padding-bottom: 6px">
									<strong>{{doc.items[0].price}},-&nbsp;Kč</strong>
								</div>
							</td>
						</tr>
						<tr>
							<td valign="top">
								<div style="padding-top: 88px">
									<small><i>Razítko</i></small>
								</div>
							</td>
							<td valign="top">
								<div style="padding-top: 88px">
									<small><i>Podpis</i></small>
								</div>
							</td>
						</tr>																		
					</table>
				</td>				
			</tr>
		</table>	
	</div>
</div>
</template>

<script>
import Vue from "vue";
import pouchError from "pouch-error";
import printFS from 'print-js';

export default {
	data() { return {
		doc: { _id:"", items: [{name:"", price:-1}]},
		billingInfo: {}
	}},
	async mounted() {
		try {
			this.doc = await this.$db.get(this.$route.query.id);
			this.billingInfo = await this.$db.get_missing_ok('settings/billingInfo');
		} catch(e) {
			pouchError(e);
		}
		window.onafterprint = (ev) => this.$router.go(-1);
		this.$nextTick(() => printJS('receipt', 'html'))
	},
}
</script>

<style lang="stylus">
.receipt
	border-style solid
	border-width 1px
	border-radius 4px
	padding-top 10px
	padding-left 20px
</style>