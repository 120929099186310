var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-padding" },
    [
      !_vm.$sync.auth.gotAuth
        ? _c(
            "q-card",
            [
              _c("q-card-title", [_vm._v("Login")]),
              _c(
                "q-card-main",
                { staticClass: "group" },
                [
                  _c("q-input", {
                    attrs: { "float-label": "Database", disable: "disable" },
                    model: {
                      value: _vm.$sync.auth.url,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "url", $$v)
                      },
                      expression: "$sync.auth.url"
                    }
                  }),
                  _c("q-input", {
                    attrs: {
                      "float-label": "Login email",
                      error: !_vm.isEmailOK
                    },
                    model: {
                      value: _vm.$sync.auth.username,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "username", $$v)
                      },
                      expression: "$sync.auth.username"
                    }
                  }),
                  _c("q-input", {
                    attrs: { "float-label": "Password", type: "password" },
                    model: {
                      value: _vm.$sync.auth.password,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "password", $$v)
                      },
                      expression: "$sync.auth.password"
                    }
                  }),
                  _c("q-checkbox", {
                    attrs: { label: "Remember me" },
                    model: {
                      value: _vm.$sync.auth.remember,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "remember", $$v)
                      },
                      expression: "$sync.auth.remember"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-card-actions",
                [
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "flat",
                        color: "primary",
                        disabled: !_vm.isEmailOK
                      },
                      on: { click: _vm.login }
                    },
                    [_vm._v("Login")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "q-card",
            [
              _c("q-card-title", [_vm._v("Already logged in")]),
              _c(
                "q-card-main",
                { staticClass: "group" },
                [
                  _c("q-input", {
                    attrs: { "float-label": "Database", disable: "disable" },
                    model: {
                      value: _vm.$sync.auth.url,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "url", $$v)
                      },
                      expression: "$sync.auth.url"
                    }
                  }),
                  _c("q-input", {
                    attrs: {
                      "float-label": "Login email",
                      error: !_vm.isEmailOK,
                      disable: "disable"
                    },
                    model: {
                      value: _vm.$sync.auth.username,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "username", $$v)
                      },
                      expression: "$sync.auth.username"
                    }
                  }),
                  _c("q-input", {
                    attrs: {
                      "float-label": "Password",
                      type: "password",
                      disable: "disable"
                    },
                    model: {
                      value: _vm.$sync.auth.password,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "password", $$v)
                      },
                      expression: "$sync.auth.password"
                    }
                  }),
                  _c("q-checkbox", {
                    attrs: { label: "Remember me", disable: "disable" },
                    model: {
                      value: _vm.$sync.auth.remember,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "remember", $$v)
                      },
                      expression: "$sync.auth.remember"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-card-actions",
                [
                  _c(
                    "q-btn",
                    {
                      attrs: { flat: "flat", color: "primary" },
                      on: { click: _vm.logout }
                    },
                    [_vm._v("Logout")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e1db5e8", { render: render, staticRenderFns: staticRenderFns })
  }
}