<template lang="pug">
	router-view
</template>

<script>

import Vue from 'vue'
Vue.config.productionTip = false

require('quasar-framework/dist/quasar.' + __THEME + '.styl')
import 'quasar-extras/roboto-font'
import 'quasar-extras/material-icons'
import 'quasar-extras/fontawesome'
import 'quasar-extras/animate'
import Quasar, * as QuasarAll from 'quasar'
Quasar.theme = __THEME;
Vue.use(Quasar, { components: QuasarAll, directives: QuasarAll, plugins: QuasarAll })

Vue.use(require('./js/vue-pouch.js'), {
	dbprefix: 'dentic-',
	remote: "https://couchdb.gitima.cz:6984",
})

import router from './router.js';

export default {
	router,
	el: '#main',
	set render(fce) { delete this.render; this.render = fce; new Vue(this); },
}

</script>
