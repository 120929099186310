<template lang="pug">
q-page(padding)
	h4 Měsíční přehledy
	.row
		q-datetime(v-model="fromDate" type="date" :first-day-of-week="1" float-label="od" :min="minDate" :max="toDate")
		q-datetime(v-model="toDate" type="date" :first-day-of-week="1" float-label="do" :min="fromDate" :max="maxDate")
	div cash={{sumPayment('cash')}} Kc, card={{sumPayment('card')}} Kc
	ul
		li(v-for="doc in docs") {{ doc._id }} {{ doc.payment_method }} {{ doc.name }} {{ doc.items[0].price }} {{ doc.date }}

</template>

<script>
import Vue from "vue";
import { date } from "quasar";

const MSECPERDAY = 1000*3600*24;

export default {
	data() { return {
		allDocs: [],
		fromDate: null, toDate: null, minDate: null, maxDate: null,
	}},
	computed: {
//		docs() { return this.allDocs.filter(({_date}) => _date >= this.fromDate_0000 && _date <= this.toDate_2359) },
		docs() { return this.allDocs.filter(({date}) => date >= "2018-12-01T00:00:00.000Z" && date <= "2018-12-31T23:59:59.999Z") },
		fromDate_0000() { return parseInt(this.fromDate/MSECPERDAY)*MSECPERDAY },
		toDate_2359() { return parseInt(this.toDate/MSECPERDAY+1)*MSECPERDAY-1 },
	},
	methods: {
		sumPayment(method) { return this.docs
			.filter(({payment_method}) => !method || payment_method===method)
			.reduce((sum,{items}) => sum+items.reduce((sum, {price}) => sum + parseFloat(price), 0), 0) },
	},
	mounted() {
		this.$db.liveFind({ selector: {
			date: {'$exists': true}
		}})
		.on('update', ({action,doc}) => {
			doc._date = (new Date(doc.date)).getTime();
			if (!this.minDate || this.minDate > doc._date) this.minDate = this.fromDate = doc._date;
			if (!this.maxDate || this.maxDate < doc._date) this.maxDate = this.toDate   = doc._date;
			if (action === 'ADD') { this.allDocs.push(doc); return; }
			let i = this.allDocs.findIndex(({_id}) => _id === doc._id);
			if (action === 'REMOVE') this.allDocs.splice(i,1);
			else Vue.set(this.allDocs, i, doc);
		})
		.on('error', (e) => ERROR(e))
	},
}
</script>

<style lang="stylus">
div.page
	padding 20px 40px
</style>