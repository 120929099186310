<template lang="pug">

q-layout(ref="layout" view="hHh LpR fFf")

	//- Header
	q-layout-header(reveal)
		//- Navigation toolbar (on mobile)
		q-toolbar(v-if="$q.platform.is.mobile")
			q-btn(dense round flat @click="leftSide =! leftSide" icon="menu")
			q-toolbar-title Dentic
		//- Navigation tabs (on desktop)
		q-tabs(v-else)
			q-route-tab(slot="title" v-for="item in menu" :key="item.path" :label="item.label"
				:to="{name: item.path, params: $route.params}" :name="item.path" exact)

	//- Left-side Drawer
	q-layout-drawer(v-model="leftSide" side="left")
		//- Navigation menu (on mobiley)
		template(v-if="$q.platform.is.mobile")
			q-item(v-for="item in menu" v-if="item.label" :key="item.path" :to="{name: item.path, params: $route.params}")
				q-item-side(:icon="item.icon")
				q-item-main(:label="item.label")
			q-item-separator

		//- Submenu
		q-item(v-for="item in submenu" v-if="item.label" :key="item.path" :to="{name: currentTab+'/'+item.path, params: $route.params}")
			q-item-side(:icon="item.icon")
			q-item-main(:label="item.label")
		q-item-separator(v-if="submenu")
		q-item(link @click.native="$sync.logout()")
			q-item-side(icon="power settings new")
			q-item-main(label="Logout")

	//- Main content
	q-page-container: router-view(:key="$route.params.dbname")

</template>

<script>

import menu from './menu.js';

export default {
	data() { return {
		menu,
		leftSide: true,
	}},
	computed: {
		currentTab() { return this.$route.name.split('/')[0] },
		submenu() {
			let item = menu.find(({path})=>path==this.currentTab)||{};
			return item.children;
		},
	},
}

</script>
