<template lang="pug">
q-page(style="padding: 20px 40px")
	h4 Fakturační údaje
	div.row.group
		div.column-l
			q-input(name="company" v-model="doc.company" stack-label="Obchodní název" placeholder="Zadejte obchodní název")
	div.row.group
		div.column-l
			q-input(name="name" v-model="doc.name" stack-label="Jméno" placeholder="Zadejte Titul Jméno Příjmení")
		div.column-r
			q-input(name="street" v-model="doc.street" stack-label="Ulice" placeholder="Zadejte ulici a č.p.")
	div.row.group
		div.column-l
			q-input(name="idnum" v-model="doc.idnum" stack-label="IČ" placeholder="Zadejte IČ")
		div.column-r
			q-input(name="city" v-model="doc.city" stack-label="Město" placeholder="Zadejte město")
	div.row.group
		div.column-l
			q-input(name="mobile" v-model="doc.mobile" stack-label="Telefonní číslo" placeholder="Zadejte číslo mob. telefonu")
		div.column-r
			q-input(name="zip" v-model="doc.zip" stack-label="PSČ" placeholder="Zadejte PSČ")
	div.row.group
		div.column-l
			q-input(name="email" v-model="doc.email" stack-label="E-mail" placeholder="Zadejte mailovou adresu")
	div.row.group
		div.column-l
			q-input(name="web" v-model="doc.web" stack-label="Webová adresa" placeholder="Zadejte webovou adresu")
	q-btn(icon="create" color="primary" style="margin-top: 30px" @click="upset") Uložit změny
	
</template>

<script>
import Vue from "vue";
import pouchError from "pouch-error";

export default {
	data() { return {
		doc: {_id: "settings/billingInfo"}
	}},
	methods: {
		upset() {
			this.$db.upset(this.doc).catch(e => pouchError(e))
		},
	},
	mounted() {
		this.$db.get_missing_ok(this.doc._id).then(doc => this.doc = doc).catch(e => pouchError(e));
	},
}
</script>

<style lang="stylus">
div.column-l, div.column-r
	width: 350px
	floating: left
div.column-r
	padding-left: 80px
</style>