var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-page", { attrs: { padding: "padding" } }, [
    _c("h4", [_vm._v("Měsíční přehledy")]),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("q-datetime", {
          attrs: {
            type: "date",
            "first-day-of-week": 1,
            "float-label": "od",
            min: _vm.minDate,
            max: _vm.toDate
          },
          model: {
            value: _vm.fromDate,
            callback: function($$v) {
              _vm.fromDate = $$v
            },
            expression: "fromDate"
          }
        }),
        _c("q-datetime", {
          attrs: {
            type: "date",
            "first-day-of-week": 1,
            "float-label": "do",
            min: _vm.fromDate,
            max: _vm.maxDate
          },
          model: {
            value: _vm.toDate,
            callback: function($$v) {
              _vm.toDate = $$v
            },
            expression: "toDate"
          }
        })
      ],
      1
    ),
    _c("div", [
      _vm._v(
        "cash=" +
          _vm._s(_vm.sumPayment("cash")) +
          " Kc, card=" +
          _vm._s(_vm.sumPayment("card")) +
          " Kc"
      )
    ]),
    _c(
      "ul",
      _vm._l(_vm.docs, function(doc) {
        return _c("li", [
          _vm._v(
            _vm._s(doc._id) +
              " " +
              _vm._s(doc.payment_method) +
              " " +
              _vm._s(doc.name) +
              " " +
              _vm._s(doc.items[0].price) +
              " " +
              _vm._s(doc.date)
          )
        ])
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3fdf8cfa", { render: render, staticRenderFns: staticRenderFns })
  }
}