<template lang="pug">
q-page(padding)
	q-card(v-if="!$sync.gotAuth")
		q-card-title Backup and synchronization
			div(slot="right"): q-icon(name="bug report" @click.native="click++" :color="debugMode?'red':''")
		q-card-main.group
			q-input(v-model="$sync.auth.url" float-label="Database" :disabled="!debugMode")
			q-input(v-model="$sync.auth.username" float-label="Login email" :error="!isEmailOK")
			q-input(v-model="$sync.auth.password" float-label="Password" type="password")
			q-checkbox(v-model="$sync.auth.remember" label="Remember me")
		q-card-actions
			q-btn(flat color="primary" :disabled="!isEmailOK && !debugMode" @click="$sync.login()") Login
	q-card(v-else)
		q-card-title Backup and synchronization
			div(slot="right"): q-icon(name="bug report" @click.native="click++" :color="debugMode?'red':''")
		q-card-main.group
			q-input(v-model="$sync.auth.url" float-label="Database" disabled)
			q-input(v-model="$sync.auth.username" float-label="Login email" :error="!isEmailOK" disabled)
		q-card-actions
			q-btn(flat color="primary" @click="$sync.logout()") Logout
	pre(v-if="debugMode") $sync = {{$sync}}
	q-btn(v-if="debugMode" @click="update") update DB
	//- pre $db.name = {{$db.name}}
</template>

<script>
import Vue from "vue";
import validator from 'validator';

export default {
	data() { return {
		click: 0,
	}},
	computed: {
		isEmailOK() { return validator.isEmail(this.$sync.auth.username||'') },
		debugMode() { return this.click>=3 },
	},
	methods: {
		update() { this.$db1.a++; this.$db.upset({_id: '(test)', date: new Date()}) },
	}
}
</script>

