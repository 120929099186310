var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    { attrs: { padding: "padding" } },
    [
      !_vm.$sync.gotAuth
        ? _c(
            "q-card",
            [
              _c("q-card-title", [
                _vm._v("Backup and synchronization"),
                _c(
                  "div",
                  { attrs: { slot: "right" }, slot: "right" },
                  [
                    _c("q-icon", {
                      attrs: {
                        name: "bug report",
                        color: _vm.debugMode ? "red" : ""
                      },
                      nativeOn: {
                        click: function($event) {
                          _vm.click++
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "q-card-main",
                { staticClass: "group" },
                [
                  _c("q-input", {
                    attrs: {
                      "float-label": "Database",
                      disabled: !_vm.debugMode
                    },
                    model: {
                      value: _vm.$sync.auth.url,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "url", $$v)
                      },
                      expression: "$sync.auth.url"
                    }
                  }),
                  _c("q-input", {
                    attrs: {
                      "float-label": "Login email",
                      error: !_vm.isEmailOK
                    },
                    model: {
                      value: _vm.$sync.auth.username,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "username", $$v)
                      },
                      expression: "$sync.auth.username"
                    }
                  }),
                  _c("q-input", {
                    attrs: { "float-label": "Password", type: "password" },
                    model: {
                      value: _vm.$sync.auth.password,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "password", $$v)
                      },
                      expression: "$sync.auth.password"
                    }
                  }),
                  _c("q-checkbox", {
                    attrs: { label: "Remember me" },
                    model: {
                      value: _vm.$sync.auth.remember,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "remember", $$v)
                      },
                      expression: "$sync.auth.remember"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-card-actions",
                [
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "flat",
                        color: "primary",
                        disabled: !_vm.isEmailOK && !_vm.debugMode
                      },
                      on: {
                        click: function($event) {
                          _vm.$sync.login()
                        }
                      }
                    },
                    [_vm._v("Login")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "q-card",
            [
              _c("q-card-title", [
                _vm._v("Backup and synchronization"),
                _c(
                  "div",
                  { attrs: { slot: "right" }, slot: "right" },
                  [
                    _c("q-icon", {
                      attrs: {
                        name: "bug report",
                        color: _vm.debugMode ? "red" : ""
                      },
                      nativeOn: {
                        click: function($event) {
                          _vm.click++
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "q-card-main",
                { staticClass: "group" },
                [
                  _c("q-input", {
                    attrs: { "float-label": "Database", disabled: "disabled" },
                    model: {
                      value: _vm.$sync.auth.url,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "url", $$v)
                      },
                      expression: "$sync.auth.url"
                    }
                  }),
                  _c("q-input", {
                    attrs: {
                      "float-label": "Login email",
                      error: !_vm.isEmailOK,
                      disabled: "disabled"
                    },
                    model: {
                      value: _vm.$sync.auth.username,
                      callback: function($$v) {
                        _vm.$set(_vm.$sync.auth, "username", $$v)
                      },
                      expression: "$sync.auth.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-card-actions",
                [
                  _c(
                    "q-btn",
                    {
                      attrs: { flat: "flat", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.$sync.logout()
                        }
                      }
                    },
                    [_vm._v("Logout")]
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm.debugMode
        ? _c("pre", [_vm._v("$sync = " + _vm._s(_vm.$sync))])
        : _vm._e(),
      _vm.debugMode
        ? _c("q-btn", { on: { click: _vm.update } }, [_vm._v("update DB")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-78d6a0b7", { render: render, staticRenderFns: staticRenderFns })
  }
}