<template>
<div style="width: 660px; margin: auto" id="receipt">
	<div class="row" style="width: 100%">
		<div style="floating: left; width: 50%">
			<p class="big strong" style="padding-left: 20px">Příjmový pokladní doklad</p>
		</div>
		<div style="floating: right; text-align: right; width: 50%">
			<p style="padding-right: 20px">Číslo dokladu: &nbsp;&nbsp;<span class="big strong">{{doc._id.slice(-8)}}</span></p>
		</div>
	</div>
	<div class="row receipt">
		<div style="floating: left; width: 220px">
				<p class="small strong">Firma:</p>
				<p>
					<span class="italic strong">{{billingInfo.company}}</span>
					<br />
					<span class="italic">{{billingInfo.name}}</span>
					<br />
					<span class="italic">{{billingInfo.street}}</span>
					<br />
					<span class="italic">{{billingInfo.zip}} {{billingInfo.city}}</span>
				</p>
				<p>
					IČ: {{billingInfo.idnum}}
				</p>
				<p>
					Tel.: {{billingInfo.mobile}}
					<br />
					E-mail: {{billingInfo.email}}
					<br />
					{{billingInfo.web}}
				</p>
		</div>
		<div style="floating: left; width: 210px">
			<p><span class="small strong">Přijato od (jméno, rok narození):</span></p>
			<p>{{doc.name}}, {{doc.yob}}</p>
			<p style="padding-top: 18px"><span class="small strong">Datum a čas vystavení:</span></p>
			<p>{{new Date(doc.date).toLocaleString()}}</p>
		</div>
		<div style="floating: right; width: 200px">
			<p>
				<table style="margin-top: -2px">
					<tr><td><span class="small strong">Položka:</span></td>
						<td style="text-align: center"><span class="small right strong">Částka:</span></td></tr>
					<tr><td style="padding-right: 10px">{{doc.items[0].name}}</td>
						<td class="right" style="padding: 0px 10px 0px 10px">{{doc.items[0].price}},- Kč</td></tr>
					<tr><td>&nbsp;</td><td>&nbsp;</td></tr>
					<tr><td>&nbsp;</td><td>&nbsp;</td></tr>
					<tr>
						<td><strong>Celkem:</strong></td>
						<td class="right" style="border-style: solid; border-width: 1px; border-radius: 4px; padding: 0px 10px 0px 10px"><strong>{{doc.items[0].price}},- Kč</strong></td></tr>
					<tr><td>&nbsp;</td><td>&nbsp;</td></tr>
					<tr><td>&nbsp;</td><td>&nbsp;</td></tr>
					<tr><td>&nbsp;</td><td>&nbsp;</td></tr>
					<tr><td>Razítko:</td><td>Podpis:</td></tr>
				</table>
			</p>
		</div>
	</div>
</div>
</template>

<script>
import Vue from "vue";
import pouchError from "pouch-error";
import printFS from 'print-js';

export default {
	data() { return {
		doc: { _id:"", items: [{name:"", price:-1}]},
		billingInfo: {}
	}},
	async mounted() {
		try {
			this.doc = await this.$db.get('receipt/18010340');
			this.billingInfo = await this.$db.get_missing_ok('settings/billingInfo');
		} catch(e) {
			pouchError(e);
		}
		window.onafterprint = (ev) => this.$router.go(-1);
		this.$nextTick(() => printJS('receipt', 'html'))
	},
}
</script>

<style lang="stylus">
p
	margin-bottom 6px
	font-size 10pt
.big
	font-size 12pt
.italic
	font-style italic
.receipt
	border-style solid
	border-width 1px
	border-radius 4px
	padding-top 10px
	padding-left 20px
.small
	font-size 8pt
.right
	text-align right
.strong
	font-size bold
</style>