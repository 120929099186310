var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    { attrs: { padding: "padding" } },
    [
      _c("q-table", {
        attrs: {
          data: _vm.tableData,
          columns: _vm.tableColumns,
          "row-key": "_id",
          "title:": "title:",
          Přehled: "",
          dokladů: "Přehled dokladů",
          dense: "dense",
          pagination: _vm.pagination,
          "hide-bottom": _vm.tableData.length <= _vm.pagination.rowsPerPage
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body-cell-date",
            fn: function(props) {
              return _c("q-td", { attrs: { props: props } }, [
                _c("span", [
                  _vm._v(_vm._s(new Date(props.value).toLocaleString()))
                ])
              ])
            }
          },
          {
            key: "body-cell-_id",
            fn: function(props) {
              return _c("q-td", { attrs: { props: props } }, [
                _c("span", [_vm._v(_vm._s(props.value.slice(-8)))])
              ])
            }
          },
          {
            key: "body-cell-items",
            fn: function(props) {
              return _c("q-td", { attrs: { props: props } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (props.value || [])
                        .map(function(d) {
                          return parseInt(d.price) || 0
                        })
                        .reduce(function(a, b) {
                          return a + b
                        }, 0)
                    )
                  )
                ])
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ac5f3134", { render: render, staticRenderFns: staticRenderFns })
  }
}