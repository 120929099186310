var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    { staticStyle: { padding: "20px 40px" } },
    [
      _c("h4", [_vm._v("Fakturační údaje")]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: {
                name: "company",
                "stack-label": "Obchodní název",
                placeholder: "Zadejte obchodní název"
              },
              model: {
                value: _vm.doc.company,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "company", $$v)
                },
                expression: "doc.company"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: {
                name: "name",
                "stack-label": "Jméno",
                placeholder: "Zadejte Titul Jméno Příjmení"
              },
              model: {
                value: _vm.doc.name,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "name", $$v)
                },
                expression: "doc.name"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-input", {
              attrs: {
                name: "street",
                "stack-label": "Ulice",
                placeholder: "Zadejte ulici a č.p."
              },
              model: {
                value: _vm.doc.street,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "street", $$v)
                },
                expression: "doc.street"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: {
                name: "idnum",
                "stack-label": "IČ",
                placeholder: "Zadejte IČ"
              },
              model: {
                value: _vm.doc.idnum,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "idnum", $$v)
                },
                expression: "doc.idnum"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-input", {
              attrs: {
                name: "city",
                "stack-label": "Město",
                placeholder: "Zadejte město"
              },
              model: {
                value: _vm.doc.city,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "city", $$v)
                },
                expression: "doc.city"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: {
                name: "mobile",
                "stack-label": "Telefonní číslo",
                placeholder: "Zadejte číslo mob. telefonu"
              },
              model: {
                value: _vm.doc.mobile,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "mobile", $$v)
                },
                expression: "doc.mobile"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-input", {
              attrs: {
                name: "zip",
                "stack-label": "PSČ",
                placeholder: "Zadejte PSČ"
              },
              model: {
                value: _vm.doc.zip,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "zip", $$v)
                },
                expression: "doc.zip"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: {
                name: "email",
                "stack-label": "E-mail",
                placeholder: "Zadejte mailovou adresu"
              },
              model: {
                value: _vm.doc.email,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "email", $$v)
                },
                expression: "doc.email"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: {
                name: "web",
                "stack-label": "Webová adresa",
                placeholder: "Zadejte webovou adresu"
              },
              model: {
                value: _vm.doc.web,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "web", $$v)
                },
                expression: "doc.web"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "q-btn",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { icon: "create", color: "primary" },
          on: { click: _vm.upset }
        },
        [_vm._v("Uložit změny")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-103b2464", { render: render, staticRenderFns: staticRenderFns })
  }
}