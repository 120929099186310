<template lang="pug">
q-page(padding)
	h4 Kalendář
	p Zde by měl být Kalendář, ale uvidíme, zda a jestli to půjde nějak propojit s Google kalendářem, který používáte.
</template>

<script>
import Vue from "vue";

export default {
	data() { return {
	}},
}
</script>

<style lang="stylus">
div.page
	padding 20px 40px
</style>

