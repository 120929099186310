var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "800px", margin: "auto" },
      attrs: { id: "receipt" }
    },
    [
      _c("table", [
        _c("tr", [
          _vm._m(0),
          _vm._v(" "),
          _c("td", { staticStyle: { width: "25%" } }, [
            _vm._v("\r\n\t\t\t\tČíslo dokladu:   "),
            _c("strong", [_vm._v(_vm._s(_vm.doc._id.slice(-8)))])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "receipt", staticStyle: { "padding-bottom": "12px" } },
        [
          _c("table", [
            _c("tr", [
              _c(
                "td",
                { staticStyle: { width: "33%" }, attrs: { valign: "top" } },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", [_vm._v(_vm._s(_vm.billingInfo.company))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("i", [_vm._v(_vm._s(_vm.billingInfo.name))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("i", [_vm._v(_vm._s(_vm.billingInfo.street))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm.billingInfo.zip) +
                          " " +
                          _vm._s(_vm.billingInfo.city)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", [_vm._v("IČ: " + _vm._s(_vm.billingInfo.idnum))])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", [
                      _vm._v("Tel.: " + _vm._s(_vm.billingInfo.mobile))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("i", [
                      _vm._v("E-mail: " + _vm._s(_vm.billingInfo.email))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("i", [_vm._v(_vm._s(_vm.billingInfo.web))])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticStyle: { width: "33%" }, attrs: { valign: "top" } },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.doc.name) + ", " + _vm._s(_vm.doc.yob))
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(new Date(_vm.doc.date).toLocaleString()))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticStyle: { width: "33%" }, attrs: { valign: "top" } },
                [
                  _c("table", { staticStyle: { "margin-top": "-2px" } }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", { attrs: { valign: "top" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "36px",
                              "margin-top": "-5px"
                            }
                          },
                          [
                            _vm._v(
                              "\r\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.doc.items[0].name) +
                                "\r\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { valign: "top" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-bottom": "36px",
                                "margin-top": "-5px"
                              }
                            },
                            [
                              _vm._v(
                                "\r\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.doc.items[0].price) +
                                  ",- Kč\r\n\t\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { valign: "top" }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "6px" } },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.doc.items[0].price) + ",- Kč")
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(6)
                  ])
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { width: "75%", "padding-left": "20px" } }, [
      _c("strong", [_vm._v("Příjmový pokladní doklad")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "padding-bottom": "6px" } }, [
      _c("small", [_c("i", [_vm._v("Firma:")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "padding-bottom": "6px" } }, [
      _c("small", [_c("i", [_vm._v("Přijato od (jméno, rok narození):")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "padding-bottom": "6px" } }, [
      _c("small", [_c("i", [_vm._v("Datum a čas vystavení:")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticStyle: { width: "160px" }, attrs: { valign: "top" } }, [
        _c("div", { staticStyle: { "padding-bottom": "6px" } }, [
          _c("small", [_c("i", [_vm._v("Položka:")])])
        ])
      ]),
      _vm._v(" "),
      _c("td", { attrs: { valign: "top" } }, [
        _c("div", { staticStyle: { "padding-bottom": "6px" } }, [
          _c("small", [_c("i", [_vm._v("Částka:")])])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { valign: "top" } }, [
      _c("div", { staticStyle: { "padding-bottom": "6px" } }, [
        _c("strong", [_vm._v("Celkem:")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { valign: "top" } }, [
        _c("div", { staticStyle: { "padding-top": "88px" } }, [
          _c("small", [_c("i", [_vm._v("Razítko")])])
        ])
      ]),
      _vm._v(" "),
      _c("td", { attrs: { valign: "top" } }, [
        _c("div", { staticStyle: { "padding-top": "88px" } }, [
          _c("small", [_c("i", [_vm._v("Podpis")])])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2f91a239", { render: render, staticRenderFns: staticRenderFns })
  }
}