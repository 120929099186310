var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "660px", margin: "auto" },
      attrs: { id: "receipt" }
    },
    [
      _c("div", { staticClass: "row", staticStyle: { width: "100%" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              floating: "right",
              "text-align": "right",
              width: "50%"
            }
          },
          [
            _c("p", { staticStyle: { "padding-right": "20px" } }, [
              _vm._v("Číslo dokladu:   "),
              _c("span", { staticClass: "big strong" }, [
                _vm._v(_vm._s(_vm.doc._id.slice(-8)))
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row receipt" }, [
        _c("div", { staticStyle: { floating: "left", width: "220px" } }, [
          _c("p", { staticClass: "small strong" }, [_vm._v("Firma:")]),
          _vm._v(" "),
          _c("p", [
            _c("span", { staticClass: "italic strong" }, [
              _vm._v(_vm._s(_vm.billingInfo.company))
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "italic" }, [
              _vm._v(_vm._s(_vm.billingInfo.name))
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "italic" }, [
              _vm._v(_vm._s(_vm.billingInfo.street))
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "italic" }, [
              _vm._v(
                _vm._s(_vm.billingInfo.zip) + " " + _vm._s(_vm.billingInfo.city)
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\r\n\t\t\t\t\tIČ: " +
                _vm._s(_vm.billingInfo.idnum) +
                "\r\n\t\t\t\t"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\r\n\t\t\t\t\tTel.: " +
                _vm._s(_vm.billingInfo.mobile) +
                "\r\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "\r\n\t\t\t\t\tE-mail: " +
                _vm._s(_vm.billingInfo.email) +
                "\r\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "\r\n\t\t\t\t\t" + _vm._s(_vm.billingInfo.web) + "\r\n\t\t\t\t"
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { floating: "left", width: "210px" } }, [
          _vm._m(1),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.doc.name) + ", " + _vm._s(_vm.doc.yob))]),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(new Date(_vm.doc.date).toLocaleString()))])
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { floating: "right", width: "200px" } }, [
          _c("p", [
            _c("table", { staticStyle: { "margin-top": "-2px" } }, [
              _vm._m(3),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticStyle: { "padding-right": "10px" } }, [
                  _vm._v(_vm._s(_vm.doc.items[0].name))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "right",
                    staticStyle: { padding: "0px 10px 0px 10px" }
                  },
                  [_vm._v(_vm._s(_vm.doc.items[0].price) + ",- Kč")]
                )
              ]),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("tr", [
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "right",
                    staticStyle: {
                      "border-style": "solid",
                      "border-width": "1px",
                      "border-radius": "4px",
                      padding: "0px 10px 0px 10px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.doc.items[0].price) + ",- Kč")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _vm._m(10)
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { floating: "left", width: "50%" } }, [
      _c(
        "p",
        { staticClass: "big strong", staticStyle: { "padding-left": "20px" } },
        [_vm._v("Příjmový pokladní doklad")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "small strong" }, [
        _vm._v("Přijato od (jméno, rok narození):")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "padding-top": "18px" } }, [
      _c("span", { staticClass: "small strong" }, [
        _vm._v("Datum a čas vystavení:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [
        _c("span", { staticClass: "small strong" }, [_vm._v("Položka:")])
      ]),
      _vm._v(" "),
      _c("td", { staticStyle: { "text-align": "center" } }, [
        _c("span", { staticClass: "small right strong" }, [_vm._v("Částka:")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v(" ")]), _c("td", [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v(" ")]), _c("td", [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Celkem:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v(" ")]), _c("td", [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v(" ")]), _c("td", [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v(" ")]), _c("td", [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Razítko:")]),
      _c("td", [_vm._v("Podpis:")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5871055f", { render: render, staticRenderFns: staticRenderFns })
  }
}