
const CONNECTION_TIMEOUT = 7;	// in sec, max timeout for an HTTP request

import Vue from 'vue'
import deepEqual from 'fast-deep-equal'
import pouchError from "pouch-error";

import PouchDB from 'pouchdb'
PouchDB.plugin(require('pouchdb-find').default);
PouchDB.plugin(require('pouchdb-live-find'));
if (process.env.NODE_ENV === 'production') PouchDB.debug.disable('pouchdb:find');

class xPouchDB extends PouchDB {
	constructor(...args) {
		super(...args);
	}

	get_missing_ok(docId, options={}, callback) {
		if (options instanceof Function) { callback = options; options = {}; }
		return this.get(docId, options)
		.then(doc => callback ? callback(undefined, doc) : doc)
		.catch(err => {
			if (err && err.name=="not_found") {
				let doc = {_id: docId};
				return callback ? callback(undefined, doc) : doc;
			}
			if (callback) return callback(err);
			throw err;
		});
	}

	upset(doc, options={}, callback) {
		if (options instanceof Function) { callback = options; options = {}; }
		let doc_update = Object.assign({}, doc);
		return this.get_missing_ok(doc._id)
		.then(doc => {
			doc_update._rev = doc._rev;
			if (deepEqual(doc, doc_update)) {
				let result = {ok: true, id: doc._id, rec: doc._rev, update: false};
				return callback?callback(undefined, result):result;
			}
			// DBG("upset", doc, "->", doc_update);
			return this.put(doc_update, callback||{});
		}).catch(err => {
			if (callback) return callback(err);
			throw err;
		});
	}

	asciiSearch({
		query, fields, type,
		highlighting_pre ="<u><b>", highlighting_post = "</b></u>",
		...query_options}, callback)
	{
		let ddocname = "asciiSearch";
		let viewname = type + "-" + fields.join(",");
		let qw = query.split(/[\s.,]+/).filter(String);
		// let starttime = Date.now();
		return this.get(`_design/${ddocname}`)
		.catch(err => {
			if (err && err.name!=="not_found") throw err;
			return { _id: `_design/${ddocname}`, views: { } };
		})
		.then(ddoc => {
			if (ddoc.views[viewname]) return;
			let mapFunc = function(doc) {
				if (doc.type !== TYPE) return;
				FIELDS.forEach(function(field) {(doc[field]||"").split(/[\s.,]+/).filter(String).forEach(function(word, index) {
					var value = [field, index];
					emit(word, value);
					var diacr = "àáäąâβćčçďéěęíĺľłňńóôöòôřŕšśťúůüùýžźż"; diacr = diacr + diacr.toUpperCase();
					var ascii = "aaaaabcccdeeeilllnnooooorrsstuuuuyzzz"; ascii = ascii + ascii.toUpperCase();
					var aw = word.split('').map(function(c) { return ascii[diacr.indexOf(c)] || c}).join('');
					var lc = word.toLowerCase();
					if (word !== aw) emit(aw, value);
					if (word !== lc) emit(lc, value);
					if (word !== lc && word != aw) emit(aw.toLowerCase(), value);
				})});
			}
			let mapFunc2 = mapFunc.toString().replace(/TYPE/g, JSON.stringify(type)).replace(/FIELDS/g, JSON.stringify(fields));
			ddoc.views[viewname] = { map: mapFunc2.toString() };
			return this.put(ddoc);
		})
		.then(put_ddoc_result => {
			if (put_ddoc_result) DBG(`asciiSearch index '${viewname}' created:`, put_ddoc_result);
			return Promise.all(qw.map(word => this.query(`${ddocname}/${viewname}`, {startkey: word, endkey: word+'\ufff0', ...query_options})));
		})
		.then(results => {
			function score(id, found=[]) {
				let qi = found.length; if (qi >= results.length) return found;
				for(let row of results[qi].rows.filter(row => row.id === id)) {
					if (found.some(item => item.field===row.value[0] && item.index===row.value[1])) continue;
					let item = {query: qw[qi], field: row.value[0], index: row.value[1]};
					let items = score(id, [item, ...found]); if (items) return items;
				}
			}
			let rows = [];
			for (let id of [...new Set(results[0].rows.map(row=>row.id))]) {
				let items = score(id); if (!items) continue;
				let row = {id};
				if (query_options.include_docs) {
					row.doc = results[0].rows.find(row=>row.id==id).doc;
					let hi = {};
					for(let {field, index, query} of items) {
						if (!hi[field]) hi[field] = row.doc[field].toString().split(/([\s.,]+)/);
						let word = hi[field][index*2];
						hi[field][index*2] = highlighting_pre + word.substring(0, query.length) + highlighting_post + word.substring(query.length);
					}
					row.highlighting = Object.entries(hi).reduce((o,[k,v])=>({...o,[k]:v.join('')}),{});
				}
				rows.push(row);
			}
			// DBG("search take", Date.now()-starttime, "msec");
			return callback?callback(undefined, rows):rows;
		})
		.catch(err => {
			if (callback) return callback(err);
			throw err;
		});
	}
}

/* common auth errors:
     bad username/password:  {status: 401, name: "unauthorized", message: "Name or password is incorrect."}
     no username & password: {status: 401, name: "unauthorized", message: "You are not authorized to access this db."}
     no permission to DB:    {status: 403, name: "forbidden", message: "You are not allowed to access this db."}
     DB does not exist:      {status: 404, name: "not_found", message: "Database does not exist."}
     bad url/ssl/net/...:    {status: 0, name: "unknown", message: undefined}
*/

class SyncPouchDB {
	constructor($db, remote) {
		this.localDB = $db;
		Object.defineProperty(this, 'localDB', {enumerable: false});
		this.authError = this.authErrorMessage = undefined;
		this.auth = { _id: "_local/$sync", url: remote + '/' + $db.name, username: "", password: "", remember: true };
		this.push = { state: 'instantiated', local_seq0: -1, last_seq: -1, localDB: `${$db.name}` };
		this.pull = { state: 'instantiated', pending: -1, remote_seq0: -1, last_seq: -1 };
		this.replicators = {};
		Object.defineProperty(this, 'replicators', {enumerable: false});
		$db.info((err, info) => this.push.local_seq0 = info.update_seq);
		// $db.changes({live:true, since:'now'}).on('change', change => DBG("local change:", change))
	}

	async getAuth() {
		try {
			this.auth = await this.localDB.get(this.auth._id);
			// DBG("auth:", this.auth);
		} catch(e) {
			if (e.name != "not_found") pouchError(e);
		}
		return this.auth;
	}

	async login() {
		let remote_options = {
			name: this.auth.url,
			ajax: {timeout: CONNECTION_TIMEOUT*1000},
			skip_setup: true, // do not try to create the DB it does not exists
		};
		if (this.auth.username) remote_options.auth = { username: this.auth.username, password: this.auth.password };
		try {
			if (this.remoteDB) this.remoteDB.close().catch(e => pouchError(e));
			this.remoteDB = new PouchDB(remote_options);
			Object.defineProperty(this, 'remoteDB', {enumerable: false});
			let info = await this.remoteDB.info();
			// DBG("remoteDB info:", info)
			this.pull.remote_seq0 = parseInt(info.update_seq);
			this.authError = this.authErrorMessage = undefined;
			if (!this.auth.gotAuth) this.auth.gotAuth = new Date;
			this.sync();
			// this.remoteDB.changes({live:true, since:'now'}).on('change', change => DBG("remoteDB change:", change))
		} catch(err) {
			if (err && err.name=="unknown" && !err.message) err=new Error("Network is unreachable");
			if (err && err.name=="unauthorized" || err.name=="forbidden") this.auth.gotAuth = false;
			pouchError(err);
			this.authError = err; this.authErrorMessage = pouchErrMessage(err);
		}
		if (this.auth.remember) this.localDB.upset(this.auth).catch(e => pouchError(e));
		else this.localDB.upset({...this.auth, password: null, username: null}).catch(e => pouchError(e));
		return !this.authError;
	}

	logout() {
		this.auth.gotAuth = this.authError = this.authErrorMessage = undefined;
		this.localDB.upset({_id: this.auth._id, _deleted: true}).catch(e => pouchError(e));
		if (this.replicators.from) this.replicators.from.cancel();
		delete this.replicators.from;
		if (this.replicators.to) this.replicators.to.cancel();
		delete this.replicators.to;
		if (this.remoteDB) this.remoteDB.close().catch(e => pouchError(e));
		delete this.remoteDB;
	}

	sync() {
		// TODO: debounce, notify push/pull error only once
		this.replicators.from = this.localDB.replicate.from(this.remoteDB, {live:true, retry: true})
		.on('complete', info => this.pull.state = "complete")
		.on('change', change => { this.pull.pending = change.pending; this.pull.last_seq = parseInt(change.last_seq); } )
		.on('active', () => { this.pull.state = "active"; delete this.pull.err; })
		.on('paused', err => {
			if (!err) { this.pull.state ="pending"; return; }
			this.pull.state = "paused"; this.pull.err = err;
			if (err.code === "ETIMEDOUT") {
				console.warn("Replications from remote DB has temporary failure:", err);
				return;
			}
			console.error("Replication from remote DB has temporary failure:", err);
			// pouchError(err);
		})
		.on('error', err => {
			this.pull.state = "error"; this.pull.err = err;
			console.error("Replication from remote DB is stopped due to an unrecoverable failure.");
			pouchError(err);
			// if (err.name == "forbidden") this.auth.gotAuth = false;
		})
		.on('denied', err => {
			this.pull.state = "error"; this.pull.err = err;
			console.error("Replication from remote DB access denied.");
			pouchError(err);
			// TODO: remove gotAuth
		})

		this.replicators.to = this.localDB.replicate.to(this.remoteDB, {live:true, retry: true})
		.on('complete', info => this.push.state = "complete")
		.on('change', change => { this.push.last_seq = change.last_seq; } )
		.on('active', () => { this.push.state = "active"; delete this.push.err; })
		.on('paused', err => {
			if (!err) { this.push.state ="pending"; return; }
			this.push.state = "paused"; this.push.err = err;
			console.error("Replication to remote DB has temporary failure.", err.message);
			pouchError(err);
			if (err.name === "compilation_error") this.replicators.to.cancel();
		})
		.on('error', err => {
			this.push.state = "error"; this.push.err = err;
			console.error("Replication to remote DB is stopped due to an unrecoverable failure.");
			pouchError(err);
			// if (err.name == "forbidden") this.auth.gotAuth = false;
		})
		.on('denied', err => {
			this.push.state = "error"; this.push.err = err;
			console.error("Replication to remote DB access denied.");
			pouchError(err);
			// TODO: remove gotAuth
		})
	}
}

export function install(Vue, {dbprefix="", remote}) {
	let $dbs = {};
	let $syncs = {};

	function encodeDbname(name) {
		// database name must match ^[a-z][a-z0-9_$()+/-]*$
		// => escape '$' and other chars with $XX (ASCII) or $uXXXX (UTF-16)
		let dbname = name.split('').map(c => {
			if (c.match(/[a-z0-9_()+/-]/)) return c;
			let hex = c.charCodeAt(0).toString(16).toLowerCase();
			return (hex.length<=2) ? `$${hex.padStart(2, '0')}` : `$u${hex.padStart(4, '0')}`;
		}).join('');
		return dbname.match(/^[a-z]/) ? dbname : `x-${dbname}`;
	}

	async function chechAuthBeforeRoute(to, from, next) {
		// DBG(`beforeRouteNeco: ${from.path}->${to.path}`);
		if (!to.params.dbname) return next();
		let dbname = encodeDbname(dbprefix + to.params.dbname);
		if (!$dbs[dbname]) {
			let options = { name: dbname, adapter: 'websql' in PouchDB.adapters ? 'websql' : undefined };
			let $db = $dbs[dbname] = new xPouchDB(options);
			$db.on('error', function (err) { console.error("PouchDB ERROR:", err); });
			let $sync = $syncs[dbname] = new SyncPouchDB($db, remote);
			let auth = await $sync.getAuth();
			if (auth.username) $sync.login().then(() => {
				// TODO: if !gotAuth && requiresAuth => redirect to login
			});
		}
		let requiresAuth = to.matched.some(r => r.meta.requiresAuth);
		if (!requiresAuth || $syncs[dbname].auth.gotAuth) return next();
		next({name: 'login', params: {...to.params, redirect: to.fullPath}});
	}

	Vue.mixin({
		beforeCreate() {
			// DBG(`beforeCreate: path=${(this.$route||{}).path}, params:`, (this.$route||{}).params);
			if (!this.$route || !this.$route.params.dbname) return;
			let dbname = encodeDbname(dbprefix + this.$route.params.dbname);
			// DBG("beforeCreate:", dbname, this.$route);
			this.$db = $dbs[dbname];
			Vue.util.defineReactive(this, '$sync', $syncs[dbname]);
		},
		created() {
			if (!this.$route || !this.$route.params.dbname) return;
			this.$watch('$route.params.dbname', (params_dbname) => {
				let dbname = encodeDbname(dbprefix + params_dbname);
				this.$db = $dbs[dbname];
				Vue.util.defineReactive(this, '$sync', $syncs[dbname]);
			});
			this.$watch('$sync.auth.gotAuth', (gotAuth) => {
				// DBG("gotAuth", gotAuth);
				if (!gotAuth) this.$router.replace({name: 'login', params: {...this.$route.params, redirect: this.$route.fullPath}});
			});
		},
		beforeRouteEnter: chechAuthBeforeRoute,
		beforeRouteUpdate: chechAuthBeforeRoute,
	});
};


export function pouchErrMessage(err) {
	if (!err) return "(no error message)";
	if ((err.constructor||{}).name === "PouchError" || !(err instanceof Error) && 'name' in err) {
		let message = err.message;
		if (err.result) {
			if (!message.endsWith(" with ")) message += ": ";
			if (err.result.ok) message += "OK";
			else if (err.result.errors && err.result.errors.length>0) message += err.result.errors.join('; ');
			else message += "an error";
		}
		if (!message) message = "(no error message)";
		if (err.name) message = `(${err.name}) ${message}`;
		return message;
	}
	if (err instanceof Error) return err.toString();
	return "(unexpected error)";
}
