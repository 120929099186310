<template lang="pug">
q-page(padding)
	q-table(:data="tableData" :columns="tableColumns" row-key="_id" title: 'Přehled dokladů' dense
		:pagination.sync="pagination" :hide-bottom="tableData.length <= pagination.rowsPerPage")
		q-td(slot="body-cell-date" slot-scope="props" :props="props")
			span {{new Date(props.value).toLocaleString()}}
		q-td(slot="body-cell-_id" slot-scope="props" :props="props")
			span {{props.value.slice(-8)}}
		q-td(slot="body-cell-items" slot-scope="props" :props="props")
			span {{(props.value||[]).map(d=>parseInt(d.price)||0).reduce((a,b)=>a+b,0)}}
</template>

<script>
import Vue from "vue";

const ERROR = (...args) => console.log("%cERROR Přehled dokladů:", "color:red", ...args);

const tableColumns = [
	{ label: 'Datum', field: 'date', name: 'date', sortable: true, sort: (a,b) => parseInt(a)-parseInt(b) },
	{ label: 'Číslo dokladu', field: '_id', name: '_id', sortable: true, sort: (a,b) => a.localeCompare(b) },
	{ label: 'Jméno', field: 'name', name: 'name', sortable: true, sort: (a,b) => a.localeCompare(b) },
	{ label: 'Částka', field: 'items', name: 'items' },
]

export default {
	data() { return {
		tableData: [],
		tableColumns,
		pagination: { rowsPerPage: 100 },
	}},
	mounted() {
		this.$db.liveFind({ selector: {
			date: {'$exists': true}
		}})
		.on('update', ({action,doc}) => {
			if (action === 'ADD') { this.tableData.push(doc); return; }
			let i = this.tableData.findIndex(({_id}) => _id === doc._id);
			if (action === 'REMOVE') this.tableData.splice(i,1);
			else Vue.set(this.tableData, i, doc);
		})
		.on('error', (e) => ERROR(e))
	},
}
</script>

