var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-page", { attrs: { padding: "padding" } }, [
    _c("h4", [_vm._v("Dnes")]),
    _c("p", [
      _vm._v(
        "Na této stránce se můžete těšit na aktuální přehled objednaných pacientů na dnešní den."
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b16777a", { render: render, staticRenderFns: staticRenderFns })
  }
}