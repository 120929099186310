var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    { attrs: { padding: "padding" } },
    [
      _c("h4", [_vm._v("Nový zákazník")]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-select", {
              attrs: {
                options: _vm.tB_selectOptions,
                "stack-label": "Titul před"
              },
              model: {
                value: _vm.doc.titleBefor,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "titleBefor", $$v)
                },
                expression: "doc.titleBefor"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-select", {
              attrs: {
                options: _vm.tA_selectOptions,
                "stack-label": "Titul za"
              },
              model: {
                value: _vm.doc.titleAfter,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "titleAfter", $$v)
                },
                expression: "doc.titleAfter"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: { name: "item", "stack-label": "Příjmení Jméno" },
              model: {
                value: _vm.doc.name,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "name", $$v)
                },
                expression: "doc.name"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row group" }, [
        _c(
          "div",
          { staticClass: "column-l" },
          [
            _c("q-input", {
              attrs: { name: "item", "stack-label": "Rok narození" },
              model: {
                value: _vm.doc.yob,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "yob", $$v)
                },
                expression: "doc.yob"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-r" },
          [
            _c("q-select", {
              attrs: {
                options: _vm.iC_selectOptions,
                "stack-label": "Zdravotní pojišťovna"
              },
              model: {
                value: _vm.doc.insuranceCompany,
                callback: function($$v) {
                  _vm.$set(_vm.doc, "insuranceCompany", $$v)
                },
                expression: "doc.insuranceCompany"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "q-btn",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { icon: "save", color: "primary" },
          on: { click: _vm.create }
        },
        [_vm._v("Uložit")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f05f4a02", { render: render, staticRenderFns: staticRenderFns })
  }
}