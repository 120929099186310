<template lang="pug">
div
	h1.row.justify-center Nothing here.
	div.row.justify-center.group
		q-btn(icon="keyboard arrow left" @click="goBack" v-if="canGoBack" color="primary" push) Go back
		q-btn(icon-right="home" @click="goHome" v-if="canGoHome" color="primary" push) Go home
</template>

<script>
export default {
	data() { return {
	}},
	computed: {
		canGoBack() { return window.history.length > 1 },
		canGoHome() { return this.$route.path.split('/')[1] },
	},
	methods: {
		goBack () { this.$router.back() },
		goHome () { this.$router.replace('/'+this.$route.path.split('/')[1] + '/') },
	},
}
</script>
