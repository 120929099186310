<template lang="pug">
q-page(padding)
	h4 Nový zákazník
	div.row.group
		div.column-l
			q-select(v-model="doc.titleBefor" :options="tB_selectOptions" stack-label="Titul před")
		div.column-r
			q-select(v-model="doc.titleAfter" :options="tA_selectOptions" stack-label="Titul za")
	div.row.group
		div.column-l
			q-input(name="item" v-model="doc.name" stack-label="Příjmení Jméno")
	div.row.group
		div.column-l
			q-input(name="item" v-model="doc.yob" stack-label="Rok narození")
		div.column-r
			q-select(v-model="doc.insuranceCompany" :options="iC_selectOptions" stack-label="Zdravotní pojišťovna")
	q-btn(icon="save" color="primary" style="margin-top: 30px" @click="create") Uložit
</template>

<script>
import Vue from "vue";

export default {
	data() { return {
		doc: { type: "customer"},
		tB_selectOptions: [
			{label: '', value: 'none'},			
			{label: 'Bc.', value: 'Bc.'},
			{label: 'Ing.', value: 'Ing.'},
			{label: 'Ing. arch.', value: 'Ing. arch.'},
			{label: 'JUDr.', value: 'JUDr.'},
			{label: 'MDDr.', value: 'MDDr.'},
			{label: 'Mgr.', value: 'Mgr.'},
			{label: 'MSDr.', value: 'MSDr.'},
			{label: 'MUDr.', value: 'MUDr.'},
			{label: 'MVDr.', value: 'MVDr.'},
			{label: 'PaedDr.,', value: 'PaedDr.'},
			{label: 'PharmDr.', value: 'PharmDr.'},
			{label: 'PhDr.', value: 'PhDr.'},
			{label: 'PhMr.', value: 'PhMr.'},
			{label: 'RCDr.', value: 'RCDr.'},
			{label: 'RNDr.', value: 'RNDr.'},
			{label: 'RSDr.', value: 'RSDr.'},
			{label: 'RTDr.', value: 'RTDr.'},
			{label: 'ThDr.', value: 'ThDr.'}],
		tA_selectOptions: [
			{label: '', value: 'none'},
			{label: 'CSc.', value: 'CSc.'},
			{label: 'Dr.', value: 'Dr.'},
			{label: 'DrSc.', value: 'DrSc.'},
			{label: 'DSc.', value: 'DSc.'},
			{label: 'Ph.D.', value: 'Ph.D.'},
			{label: 'Th.D.', value: 'Th.D.'}],
		iC_selectOptions: [
			{label: '111 - Všeobecná zdravotní pojišťovna ČR', value: '111'},
			{label: '201 - Vojenská zdravotní pojišťovna ČR', value: '201'},
			{label: '205 - Česká průmyslová zdravotní pojišťovna', value: '205'},
			{label: '207 - Oborová zdravotní poj. zam. bank, poj. a stav.', value: '207'},
			{label: '209 - Zaměstnanecká pojišťovna Škoda', value: '209'},
			{label: '211 - Zdravotní pojišťovna ministerstva vnitra ČR', value: '211'},
			{label: '213 - Revírní bratrská pokladna, zdrav. poj.', value: '213'}],
	}},
	methods: {
		create() { this.$db.post(this.doc)
			.then(r => this.$router.push({name: "customers"}))
			.catch(e => pouchError(e));
		},
	},
}
</script>

<style lang="stylus">
div.column-l, div.column-r
	width: 390px
	floating: left
div.column-r
	margin-left: 60px
</style>