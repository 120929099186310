<template lang="pug">
div.layout-padding
	q-card(v-if="!$sync.auth.gotAuth")
		q-card-title Login
		q-card-main.group
			q-input(v-model="$sync.auth.url" float-label="Database" disable)
			q-input(v-model="$sync.auth.username" float-label="Login email" :error="!isEmailOK")
			q-input(v-model="$sync.auth.password" float-label="Password" type="password")
			q-checkbox(v-model="$sync.auth.remember" label="Remember me")
		q-card-actions
			q-btn(flat color="primary" :disabled="!isEmailOK" @click="login") Login
	q-card(v-else)
		q-card-title Already logged in
		q-card-main.group
			q-input(v-model="$sync.auth.url" float-label="Database" disable)
			q-input(v-model="$sync.auth.username" float-label="Login email" :error="!isEmailOK" disable)
			q-input(v-model="$sync.auth.password" float-label="Password" type="password" disable)
			q-checkbox(v-model="$sync.auth.remember" label="Remember me" disable)
		q-card-actions
			q-btn(flat color="primary" @click="logout") Logout
</template>

<script>
import Vue from "vue";
import validator from 'validator';

export default {
	data() { return {
	}},
	computed: {
		isEmailOK() { return validator.isEmail(this.$sync.auth.username||'') },
	},
	methods: {
		login() {
			this.$sync.login().then((ok) => {
				if (!ok) return;
				this.$router.replace(this.$route.params.redirect || {name: 'home', params: this.$route.params});
			});
		},
		logout() {
			this.$sync.logout();
		}
	},
}
</script>

