var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "row justify-center" }, [_vm._v("Nothing here.")]),
    _c(
      "div",
      { staticClass: "row justify-center group" },
      [
        _vm.canGoBack
          ? _c(
              "q-btn",
              {
                attrs: {
                  icon: "keyboard arrow left",
                  color: "primary",
                  push: "push"
                },
                on: { click: _vm.goBack }
              },
              [_vm._v("Go back")]
            )
          : _vm._e(),
        _vm.canGoHome
          ? _c(
              "q-btn",
              {
                attrs: { "icon-right": "home", color: "primary", push: "push" },
                on: { click: _vm.goHome }
              },
              [_vm._v("Go home")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7cb23546", { render: render, staticRenderFns: staticRenderFns })
  }
}