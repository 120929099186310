
import menu from './menu.js';

let routerView = { render: (c) => c('router-view') };

function menu2routes(menu, parent_path='') {
	return menu.map(item => {
		let path = parent_path?`${parent_path}/${item.path}`:item.path;
		let route = { path: item.path,
			children: item.children?menu2routes(item.children, path, item):undefined,
		};
		if (item.children) {
			let default_item = item.children.find(i=>i.default);
			if (!default_item) default_item = item.children[0];
			route.children.push({path:"", redirect: default_item.path, name: path});
			route.component = routerView;
		} else {
			route.components = require('./components/'+path+'.vue');
			route.name = path;
		}
		return route;
	});
}

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({ mode: 'history', routes: [
	{ path: '/:dbname/login', name: 'login', components: require("./components/login.vue") },
	{ path: '/:dbname', components: require('./layout.vue'), meta: { requiresAuth: true }, children: [
		{ path: '', name: 'home', redirect: menu.find(m=>m.default)||menu[0] },
		...menu2routes(menu),
	]},
	{ path: '/:dbname', component: routerView, meta: { requiresAuth: true }, children: [
		{ path: 'receipts/print', name: "receipts/print", components: require('./components/receipts/print.vue')},
		{ path: '*', components: require("./components/error404.vue") },
	]},
	{ path: '*', name: 'error: not found', components: require("./components/error404.vue") },
]});

export default router;
