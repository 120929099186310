<template lang="pug">
q-page(padding)
	h4 Dnes
	p Na této stránce se můžete těšit na aktuální přehled objednaných pacientů na dnešní den.
</template>

<script>
import Vue from "vue";

export default {
	data() { return {
	}},
}
</script>

<style lang="stylus">
div.page
	padding 20px 40px
</style>