var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    { attrs: { padding: "padding" } },
    [
      _c("q-table", {
        attrs: {
          data: _vm.tableData,
          columns: _vm.tableColumns,
          "row-key": "_id",
          title: "Seznam zákazníků",
          dense: "dense",
          pagination: _vm.paginationControl,
          "hide-bottom":
            _vm.tableData.length <= _vm.paginationControl.rowsPerPage
        },
        on: {
          "update:pagination": function($event) {
            _vm.paginationControl = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-df0a17ac", { render: render, staticRenderFns: staticRenderFns })
  }
}