var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { ref: "layout", attrs: { view: "hHh LpR fFf" } },
    [
      _c(
        "q-layout-header",
        { attrs: { reveal: "reveal" } },
        [
          _vm.$q.platform.is.mobile
            ? _c(
                "q-toolbar",
                [
                  _c("q-btn", {
                    attrs: {
                      dense: "dense",
                      round: "round",
                      flat: "flat",
                      icon: "menu"
                    },
                    on: {
                      click: function($event) {
                        _vm.leftSide = !_vm.leftSide
                      }
                    }
                  }),
                  _c("q-toolbar-title", [_vm._v("Dentic")])
                ],
                1
              )
            : _c(
                "q-tabs",
                _vm._l(_vm.menu, function(item) {
                  return _c("q-route-tab", {
                    key: item.path,
                    attrs: {
                      slot: "title",
                      label: item.label,
                      to: { name: item.path, params: _vm.$route.params },
                      name: item.path,
                      exact: "exact"
                    },
                    slot: "title"
                  })
                })
              )
        ],
        1
      ),
      _c(
        "q-layout-drawer",
        {
          attrs: { side: "left" },
          model: {
            value: _vm.leftSide,
            callback: function($$v) {
              _vm.leftSide = $$v
            },
            expression: "leftSide"
          }
        },
        [
          _vm.$q.platform.is.mobile
            ? [
                _vm._l(_vm.menu, function(item) {
                  return item.label
                    ? _c(
                        "q-item",
                        {
                          key: item.path,
                          attrs: {
                            to: { name: item.path, params: _vm.$route.params }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: item.icon } }),
                          _c("q-item-main", { attrs: { label: item.label } })
                        ],
                        1
                      )
                    : _vm._e()
                }),
                _c("q-item-separator")
              ]
            : _vm._e(),
          _vm._l(_vm.submenu, function(item) {
            return item.label
              ? _c(
                  "q-item",
                  {
                    key: item.path,
                    attrs: {
                      to: {
                        name: _vm.currentTab + "/" + item.path,
                        params: _vm.$route.params
                      }
                    }
                  },
                  [
                    _c("q-item-side", { attrs: { icon: item.icon } }),
                    _c("q-item-main", { attrs: { label: item.label } })
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm.submenu ? _c("q-item-separator") : _vm._e(),
          _c(
            "q-item",
            {
              attrs: { link: "link" },
              nativeOn: {
                click: function($event) {
                  _vm.$sync.logout()
                }
              }
            },
            [
              _c("q-item-side", { attrs: { icon: "power settings new" } }),
              _c("q-item-main", { attrs: { label: "Logout" } })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "q-page-container",
        [_c("router-view", { key: _vm.$route.params.dbname })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-817f7a5a", { render: render, staticRenderFns: staticRenderFns })
  }
}