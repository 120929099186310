<template lang="pug">
q-page(class="content" style="padding: 20px 40px")
	h4 Nový doklad
	div.row.group
		div.column-l
			q-select(v-model="doc.documentType" :options="dT_selectOptions" stack-label="Druh dokladu")
		div.column-r
			q-input(v-model="doc._id" stack-label="Čislo dokumentu" disabled)
	div.row.group
		div.column-l
			q-search(name="name" stack-label="Jméno" v-model="doc.name" placeholder="Zadejte jméno")
				q-autocomplete(@search="search" @selected="selected" :min-characters="2")
		div.column-r
			q-input(name="yob" v-model="doc.yob" stack-label="Rok narození" placeholder="Rok narození")
	div.row.group
		div.column-l
			q-input(name="item" v-model="doc.items[0].name" stack-label="Položka #1")
		div.column-r
			q-input(name="price" v-model="doc.items[0].price" stack-label="Cena (Kč)" placeholder="Zadejte cenu")
	div.row.group
		div.column-l
			q-datetime(v-model="doc.date" type="datetime" stack-label="Datum a čas" :display-value="displayDatetime"
				format24h :default-selection="now" clear-label="aktuální čas" @change="onDatetimeSet")
		div.column-r
			q-select(v-model="doc.payment_method" :options="pM_selectOptions" stack-label="Způsob platby")
	q-btn(icon="create" color="primary" style="margin-top: 30px" @click="create") Vytisknout a uložit
</template>

<script>
import Vue from "vue";
import pouchError from "pouch-error";
import { date } from "quasar";

export default {
	data() { return {
		now: null,
		year: null,
		doc: {
			_id: ".",
			items: [{name: "Dentální hygiena"}],
			date: null,
			documentType: 'receipt',
			payment_method: 'cash',
		},
		dT_selectOptions: [{label: 'Příjmový pokladní doklad',value: 'receipt'}],
		pM_selectOptions: [{label: 'Hotově',value: 'cash'},{label: 'Kartou',value: 'card'}]
	}},
	computed: {
		displayDatetime() { return date.formatDate(this.doc.date||this.now, 'YYYY-MM-DD HH:mm:ss') },
	},
	methods: {
		updateCurrentDatetime() {
			this.now = new Date();
			setTimeout(this.updateCurrentDatetime, 1000-this.now.getMilliseconds());
			if (!this.doc.date && (!this.year || this.year != this.now.getFullYear())) this.updateDocID();
		},
		updateDocID() {
			this.year = (this.doc.date||this.now).getFullYear();
			let YY = this.year-2000;
			this.$db.allDocs({limit:1, descending: true, startkey: `receipt/${YY}019999`, endkey: `receipt/${YY}010000`})
			.then(({rows}) => {
				let num = ((rows[0]||{}).id||"").slice(-4);
				this.doc._id = `receipt/${YY}01${(parseInt(num||0)+1).toString().padStart(4,'0')}`
			})
			.catch(e => pouchError(e));
		},
		onDatetimeSet(val) {
			if (val) {
				this.doc.date = date.adjustDate(val, {seconds: 0, milliseconds: 0});
				console.log(this.doc.date, val, this.doc.date.getFullYear(), this.year);
				if (this.doc.date.getFullYear() != this.year) this.updateDocID();
			}
		},
		create() {
			if (!this.doc.date) this.doc.date = new Date();
			this.$db.put(this.doc)
			.then(r => this.$router.push({name: "receipts/print", query: {id: this.doc._id}}))
			.catch(e => pouchError(e));
		},
		selected(x) { this.doc.yob = x.stamp},
		search(term, done) {
			this.$db.asciiSearch({include_docs: true, query: term, type: "customer", fields: ["name"] })
			.then(rows => {
				done(rows.map(({doc, highlighting}) =>
					({label: highlighting.name||doc.name, value: doc.name, stamp: highlighting.yob||doc.yob})));
			})
			.catch(e => {
				pouchError(e);
				done([]);
			})
		}
	},
	mounted() {
		this.updateCurrentDatetime();
	},
}
</script>

<style lang="stylus">
div.column-l, div.column-r
	width: 390px
	floating: left
div.column-r
	margin-left: 60px
</style>
