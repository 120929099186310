<template lang="pug">
q-page(padding)
	q-table(:data="tableData" :columns="tableColumns" row-key="_id" title='Seznam zákazníků' dense
		:pagination.sync="paginationControl" :hide-bottom="tableData.length <= paginationControl.rowsPerPage")
</template>

<script>
import Vue from "vue";
import pouchError from "pouch-error";


const DBG = (...args) => console.log("%cD customers:", "color:green", ...args);

const tableColumns = [
	{ label: 'Jméno', field: 'name', name: 'name', sortable: true, sort: (a,b) => a.localeCompare(b), align: 'left' },
	{ label: 'Rok narození', field: 'yob', name: 'yub', sortable: true, sort: (a,b) => parseInt(a)-parseInt(b) },
	{ label: 'Zdravotní pojišťovna', field: 'insuranceCompany', name: 'insuranceCompany', sortable: true, sort: (a,b) => a.localeCompare(b) },
]

export default {
	data() { return {
		tableData: [],
		tableColumns,
		paginationControl: { rowsPerPage: 100 },
	}},
	methods: {
		newCustomer() { this.$router.push({name: "customers/new-customer", params: this.$route.params})},
	},
	mounted() {
		this.$db.liveFind({ selector: {
			type: {'$eq': 'customer'}
		}})
		.on('update', ({action,doc}) => {
			if (action === 'ADD') { this.tableData.push(doc); return; }
			let i = this.tableData.findIndex(({_id}) => _id === doc._id);
			if (action === 'REMOVE') this.tableData.splice(i,1);
			else Vue.set(this.tableData, i, doc);
		})
		.on('error', (e) => pouchError(e))
	},
}
</script>
